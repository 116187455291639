import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.isMuleEnabled) {
      request = request.clone({
        url: request.url +  "?lang=" + this.translate.currentLang,
        setHeaders: {
          client_id: environment.client_id,
          client_secret: environment.client_secret
        }
      });
    } else if (request.url.startsWith(environment.restUrl)) {
      request = request.clone({
        url: request.url +  "?lang=" + this.translate.currentLang,
        setHeaders: {
          Authorization: `Basic ${btoa(environment.client_id + ':' + environment.client_secret)}`
        }
      });
    }
    return next.handle(request);
  }
}
