import { environment } from 'src/environments/environment';
import { autorun } from 'mobx';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UILayoutStore } from './stores/ui-layout.store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'body',
  template: '<wi-root></wi-root>'
})
export class BodyComponent implements OnInit, OnDestroy {
  @HostBinding('class') public cssClasses;
  private autorunDisposer: any;

  constructor(private layoutStore: UILayoutStore, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.addLangs(environment.supportedLocales);
    this.translate.setDefaultLang(environment.defaultLocale);
    this.translate.use(environment.defaultLocale);
    this.autorunDisposer = autorun(() => {
      this.cssClasses = this.layoutStore.bodyClasses;
    });
  }

  ngOnDestroy() {
    this.autorunDisposer();
  }
}
