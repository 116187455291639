import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Format the number as a monetary value.
 * Takes a useCurrency argument that defaults to true that will display the currency as well.
 * Takes a decimals argument that defaults to '1.2-2' (according to the | decimal pipe).
 *
 * In the future we should really be sending in a Money object
 * that encapsulates both the amount and the currency instead of hardcoding SEK.
 *
 * Usage:
 *   value | money:useCurrency:decimals
 * Example:
 *   {{ 1024 | money:true:1.2-2 }}
 *   formats to: 1024.00 SEK
 *   {{ 1024 | money }}
 *   will also format to: 1024.00 SEK
 */
@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
  private currency = 'SEK';

  constructor(private decimalPipe: DecimalPipe) {}

  transform(amount: number, useCurrency: boolean = true, decimals: string = '1.2-2', locale: string = 'sv'): string {
    const formattedAmount = this.decimalPipe.transform(amount, decimals, locale);

    return useCurrency ? `${formattedAmount} ${this.currency}` : formattedAmount;
  }
}

@Pipe({ name: 'moneyWithoutCurrency' })
export class MoneyWithoutCurrencyPipe implements PipeTransform {
  constructor(private moneyPipe: MoneyPipe) {}

  transform(amount: number, decimals, locale): string {
    return this.moneyPipe.transform(amount, false, decimals, locale);
  }
}
