import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './modules/app/app.module';
import { environment } from './environments/environment';

const bootstrapModule = (): void => {
  if (environment.production) {
    enableProdMode();
  }
  platformBrowserDynamic().bootstrapModule(AppModule);
};

const init = (): void => {
  return bootstrapModule();
};

init();
