import { autorun } from 'mobx';
import { action, computed, observable } from 'mobx-angular';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable()
export class UILayoutStore {
  BOXED_MODE_DEFAULT = true;
  FULLSCREEN_MODE_DEFAULT = false;

  @observable routeBodyClass = '';
  @observable isBoxedMode = this.BOXED_MODE_DEFAULT;
  @observable isFullscreenMode = this.FULLSCREEN_MODE_DEFAULT;
  @observable isFooterHidden = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.setupRouteListeners();

    autorun(() => {
    });
  }

  setupRouteListeners() {
    // Reset body class when new route is triggered.
    // It will be set to it's correct class in BodyClassResolver if specified in routes.ts
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(() => { this.setRouteBodyClass(null); });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        this.isBoxedMode = data.boxed !== undefined ? data.boxed : this.BOXED_MODE_DEFAULT;
        this.isFullscreenMode = data.fullscreen !== undefined ? data.fullscreen : this.FULLSCREEN_MODE_DEFAULT;
        this.isFooterHidden = data.footerHidden || data.fullscreen;
      });
  }

  @action showFooter(bool = true) {
    this.isFooterHidden = !bool;
  }

  @action setBoxedMode(bool = true) {
    this.isBoxedMode = bool;
  }

  @action setRouteBodyClass(routeBodyClass: string) {
    this.routeBodyClass = routeBodyClass;
  }

  @computed get bodyClasses(): string {
    return [
      this.routeBodyClass,
      this.isBoxedMode ? 'is-boxed-mode' : null,
      this.isFullscreenMode ? 'is-fullscreen-mode' : null,
    ].filter((clazz) => clazz !== null)
     .reduce((previous, clazz) => `${previous} ${clazz}`, '');
  }
}
