import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { UILayoutStore } from '../../../app/stores/ui-layout.store';

@Injectable()
export class BodyClassResolver implements Resolve<any> {
  constructor(
    private layoutStore: UILayoutStore) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const bodyClass = route.data['bodyClass'];

    this.layoutStore.setRouteBodyClass(bodyClass);

    return true;
  }
}
