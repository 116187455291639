import { Injectable } from '@angular/core';
import { Country } from '../models/country';
import { Countries } from './countries';

export interface ICountryService {
    all(): Country[];
    codes(): string[];
    name(code: string): string;
}

@Injectable()
export class CountryService implements ICountryService {

    all(): Country[] {
        return Countries.all();
    }

    codes(): string[] {
        return Countries.codes();
    }

    name(code: string): string {
        return Countries.getName(code);
    }

}
