import { DecodeHtmlStringPipe } from './pipes/decode-html-string.pipe';
import { MobxAngularModule } from 'mobx-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { PageContent } from './components/page-content/page-content.component';
import { CountryService } from './services/country.service';
import { BodyClassResolver } from './resolvers/BodyClassResolver';
import { ViewportStore } from './stores/viewport.store';
import { MoneyPipe, MoneyWithoutCurrencyPipe } from './pipes/money.pipe';
import { InlineSVGModule } from 'ng-inline-svg';
import { IconComponent } from './components/icon/icon.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SpinnerDotsComponent } from './components/spinner-dots/spinner-dots.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MobxAngularModule,
    ReactiveFormsModule,
    RouterModule,
    InlineSVGModule.forRoot(),
    MatDialogModule,
    TranslateModule
  ],
  declarations: [
    ErrorPageComponent,
    LocalizedDatePipe,
    MoneyPipe,
    MoneyWithoutCurrencyPipe,
    DecodeHtmlStringPipe,
    PageContent,
    IconComponent,
    SpinnerComponent,
    SpinnerDotsComponent
  ],
  exports: [
    CommonModule,
    ErrorPageComponent,
    FormsModule,
    LocalizedDatePipe,
    MoneyPipe,
    MoneyWithoutCurrencyPipe,
    DecodeHtmlStringPipe,
    MobxAngularModule,
    PageContent,
    ReactiveFormsModule,
    IconComponent,
    SpinnerComponent,
    SpinnerDotsComponent,
    MatDialogModule,
    TranslateModule
  ],
  providers: [
    { provide: 'ICountryService', useClass: CountryService },
    BodyClassResolver,
    ViewportStore,
    MoneyPipe
  ]
})
export class SharedComponentsModule { }
