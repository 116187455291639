export const environment = {
  production: true,
  isMuleEnabled: true,
  restUrl: 'https://api.intrum.com/web-invoice-xapi/wi-app',
  client_id: 'f008fbd8-a482-4312-9ff0-411f8c4eda3e',
  client_secret: '7f332e52-af3d-4300-863b-fa47fc54ec48',
  paymentServiceUrl: 'https://customerportal.intrum.com/payment-service',
  profile: 'prod',
  defaultLocale: 'sv',
  supportedLocales: ['dk', 'en', 'fi', 'no', 'sv']
};
