import { observable, computed } from 'mobx';
import { Injectable } from "@angular/core";

const BREAKPOINTS = {
  xs: '(max-width:575px)',

  smDown: '(max-width:767px)',
  sm: '(min-width:576px) and (max-width:767px)',
  smUp: '(min-width:576px)',

  mdDown: '(max-width:991px)',
  md: '(min-width:768px) and (max-width:991px)',
  mdUp: '(min-width:768px)',

  lgDown: '(max-width:1199px)',
  lg: '(min-width:992px) and (max-width:1199px)',
  lgUp: '(min-width:992px)',

  xl: '(min-width:1200px)',
};

@Injectable()
export class ViewportStore {
  @observable windowWidth;

  constructor() {
    window.onresize = (event: any) => this.windowWidth = event.target.innerWidth;
  }

  private ruleIsMet = (rule: string) => window.matchMedia(rule).matches;

  @computed get isXS() { return this.ruleIsMet(BREAKPOINTS.xs); };

  @computed get isSMDown() { return this.ruleIsMet(BREAKPOINTS.smDown); };
  @computed get isSM() { return this.ruleIsMet(BREAKPOINTS.sm); };
  @computed get isSMUp() { return this.ruleIsMet(BREAKPOINTS.smUp); };

  @computed get isMDDown() { return this.ruleIsMet(BREAKPOINTS.mdDown); };
  @computed get isMD() { return this.ruleIsMet(BREAKPOINTS.md); };
  @computed get isMDUp() { return this.ruleIsMet(BREAKPOINTS.mdUp); };

  @computed get isLGDown() { return this.ruleIsMet(BREAKPOINTS.lgDown); };
  @computed get isLG() { return this.ruleIsMet(BREAKPOINTS.lg); };
  @computed get isLGUp() { return this.ruleIsMet(BREAKPOINTS.lgUp); };

  @computed get isXL() { return this.ruleIsMet(BREAKPOINTS.xl); };
}
