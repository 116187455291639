import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BankLinksComponent } from '../common-components/invoice/components/bank-links/bank-links.component';
const routes: Routes = [
  {
    path: 'i',
    loadChildren: () => import('../external-web-invoice/external-web-invoice.module').then(x => x.ExternalWebInvoiceModule)
  },
  {
    path: '500',
    loadChildren: () => import('../internal-error/internal-error.module').then(x => x.InternalErrorModule)
  },
  {
    path: 'bankLinks', component: BankLinksComponent
  },
  {
    path: '**',
    loadChildren: () => import('../notfound/notfound.module').then(x => x.NotfoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
