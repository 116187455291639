import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'wi-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  @HostBinding('class') className = 'app';

  constructor() {}
}
