<div *mobxAutorun>
    <h1 translate>web_invoice.bankLinks.selectBank</h1>
    <span translate>web_invoice.{{claim.activeInvoice.recordType}}.name</span> #{{ claim.activeInvoice.invoiceNumber }}

<div class = 'link-logo'>
    <a href="https://www.handelsbanken.se/sv/privat/konton-kort-och-betala/betala/betala-rakningar" target="_blank"><img   src="/assets/img/banks/handelsbanken_logo.svg"></a>
    <a href="https://apps.seb.se/cps/ibp/wow/6000/6200/wowc6220.aspx?m1=show" target="_blank"><img   src="/assets/img/banks/seb_logo.svg"></a>
    <a href="https://netbank.nordea.se/direct-debit/search" target="_blank"><img   src="/assets/img/banks/nordea_logo.svg"></a>
    <a href="https://danskebank.se/privat/produkter/betala/betalningar#accordion-1-item-3" target="_blank"><img    src="/assets/img/banks/danske_logo.svg"></a>
    <a href="https://online.swedbank.se/app/ib/betala-overfora/autogiro" target="_blank"><img   src="/assets/img/banks/swedbank_logo.svg"></a>
    <a href="https://secure.skandia.se/home/ui/pages/payment/autogiro/sign.aspx" target="_blank"><img   src="/assets/img/banks/skandia_logo.svg"></a>
    <a href="https://www.handelsbanken.se/sv/privat/konton-kort-och-betala/betala/betala-rakningar" target="_blank"><img   src="/assets/img/banks/handelsbanken_logo.svg"></a>
    <a href="https://secure246.lansforsakringar.se/nim/#payments-direct-debet" target="_blank"><img   src="/assets/img/banks/lansforsekning_logo.svg"></a>
</div>
</div>