import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wi-bank-links',
  templateUrl: './bank-links.component.html',
  styleUrls: ['./bank-links.component.scss']
})
export class BankLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
